<template>
    <div class="row">
        <div class="col-10 offset-1">
            <table class="table">
                <tbody>
                <tr v-for="row in rows" :key="row.id">
                    <td>{{row[field]}}</td>
                    <td>
                        <div v-for="user in row.users" :key="user.uid" class="user">
                            <i class="fa fa-check" @click="single(field, row[field], user.uid)" v-if="field !=='sound'"></i>
                            ({{user.uid}})
                            <router-link :to="'/user/'+user.uid" target="_blank">
                                {{user.fullname}} ({{user.age}})
                            </router-link>
                            {{user.country}}, {{user.city}} {{user.mobile}}
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
  export default {
    name: 'double',
    data: function () {
        return {
          rows: [],
          field: null
        }
    },
    methods: {
      async single(field, value, uid) {
        await this.postData('/double', {
            field,
            value,
             uid
        });
        this.rows = this.rows.filter(row => row[field] !== value);
      },
      async fetchData() {
        this.$loading(true);
        ({ body: this.rows } = await this.getData(this.$route.fullPath));
        this.$loading(false);
      }
    },
    watch: {
      '$route' (to/*, from */) {
        if (to) {
          this.field = to.path.split(/\//).pop().replace(/s$/, '');
          this.fetchData();
        }
      }
    }
  }
</script>

<style scoped lang="scss">
    i.fa-check{
        color: #8bc34a;
        cursor: pointer;
    }
    i.fa-check:hover{
        border: 1px #8bc34a dotted;
        padding: 5px;
        border-radius: 50%;
    }
</style>
